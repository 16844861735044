import React from "react";
import LoginImage from "./LoginImage";

const Fail = ({
	error,
	cancelLogin
}) => (
	<>
		{ error
			? (
				<>
					<h1>
						Failed to send email.
						<br />
						Please try again...
					</h1>
					<div>
						{error.message}
					</div>
				</>
			)
			: (
				<h1>
					Ran out of time!
					<br />
					Please try again...
				</h1>
			)}
		<div>
			<div>
				<LoginImage image="fail-icon.svg" alt="fail" />
			</div>
		</div>
		<div>
			<input type="button" onClick={cancelLogin} value="Try again" />
		</div>
	</>
);

Fail.defaultProps = {
	error: null
};

export default Fail;
