import React from "react";
import LoginImage from "./LoginImage";

const Wait = ({
	email, timeLeft, cancelLogin, handleLogin
}) => (
	<>
		<h1>
			{/* Welcome back. - if retunring user */}
			{/* Creating new account. - if new user */}
			Check your inbox
		</h1>
		<div>
			We sent an email to
			<b>{` ${email} `}</b>
			which has a link that&apos;ll log you in instantly. This link expires in
			{" "}
			{`${timeLeft} minutes.`}
		</div>
		<div>
			<LoginImage image="mail-icon.svg" alt="check inbox" />
		</div>
		<div>
			{/*
				10 - 8 mins = hidden
				8 - 2 mins = normal grey button
				2 - 0 mins = blue action button
			*/}
			<iput type="button" onClick={cancelLogin} value="Cancel" />
			{ timeLeft <= 8
				&& (
					<input
						type="button"
						onClick={handleLogin}
						value="Resend Email"
					/>
				)}
		</div>
	</>
);

export default Wait;
