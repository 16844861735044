import React, {
	useEffect, useCallback, useState, useRef
} from "react";
import { navigate } from "gatsby";
import { generateRandomString, isEmail } from "common/includes";
import { authServer, logonDelay } from "common/app-settings";
import useAuth from "hooks/useAuth";
import Layout from "components/layout/Layout";
import Page from "components/layout/Page";
import Fail from "components/loginContent/Fail";
import Success from "components/loginContent/Success";
import LoginPrompt from "components/loginContent/LoginPrompt";
import Wait from "components/loginContent/Wait";

const Login = () => {
	const {
		isLoggedIn, appleVerify, checkSocket, waitForSocket, sendMagicLink, cancelSocket
	} = useAuth();
	const timerRef = useRef();
	const [email, setEmail] = useState();
	const [dialogState, setDialogState] = useState("login");
	const [error, setError] = useState();
	const [timeLeft, setTimeLeft] = useState(10);
	const [isEmailValid, setIsEmailValid] = useState(false);

	useEffect(() => {
		if (!window.AppleID) {
			const appleScript = document.createElement("script");
			appleScript.id = "appleScript";
			appleScript.crossOrigin = "anonymous";
			appleScript.async = true;
			appleScript.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_GB/appleid.auth.js";
			appleScript.onload = () => {
				window.AppleID.auth.init({
					clientId: "com.vpop-pro.app",
					scope: "name email",
					state: generateRandomString(16),
					nonce: generateRandomString(16),
					redirectURI: `${authServer}auth/apple/verify`,
					usePopup: true // or false defaults to false
				});
			};
			document.body.appendChild(appleScript);
		}
		else {
			window.AppleID.auth.init({
				clientId: "com.vpop-pro.app",
				scope: "name email",
				state: generateRandomString(16),
				nonce: generateRandomString(16),
				redirectURI: `${authServer}auth/apple/verify`,
				usePopup: true // or false defaults to false
			});
		}

		return () => {
			const scriptTag = document.getElementById("appleScript");
			if (scriptTag) {
				scriptTag.onload = null;
				document.body.removeChild(scriptTag);
			}
		};
	}, []);

	const showSuccess = useCallback(() => {
		setDialogState("success");
		window.removeEventListener("focus", showSuccess);
	}, []);

	const loginCallback = useCallback(({ success, error: callbackError }) => {
		if (timerRef.current) {
			clearInterval(timerRef.current);
			timerRef.current = undefined;
		}
		if (success) {
			showSuccess();
			navigate("/client/");
		}
		else {
			setError(callbackError);
			setDialogState("fail");
		}
	}, [showSuccess]);

	const doApplyVerify = useCallback((data) => {
		const { authorization } = (data).detail;
		appleVerify({ auth: authorization, callback: loginCallback });
		setTimeout(() => navigate("/"), logonDelay);
	}, [appleVerify, loginCallback]);

	useEffect(() => {
		document.addEventListener("AppleIDSignInOnSuccess", doApplyVerify);

		return () => {
			document.removeEventListener("AppleIDSignInOnSuccess", doApplyVerify);
		};
	}, [doApplyVerify]);

	useEffect(() => {
		checkSocket().then(async (reOpen) => {
			if (reOpen) {
				setDialogState("wait");
				const expiry = window.localStorage.getItem("loginSocketExp");
				if (expiry) {
					const tl = Math.ceil((expiry - Date.now()) / (1000 * 60));
					setTimeLeft(tl);
				}
				waitForSocket(true, loginCallback);
			}
		});
	}, [checkSocket, loginCallback, waitForSocket]);

	const validateEmail = (checkEmail) => {
		const isValid = isEmail(checkEmail);
		setIsEmailValid(isValid);
		return isValid;
	};

	const handleLogin = useCallback((e) => {
		e?.preventDefault?.();
		if (validateEmail(email)) {
			sendMagicLink({ email, callback: loginCallback });
			setTimeLeft(10);
			setDialogState("wait");
			timerRef.current = setInterval(() => {
				setTimeLeft((time) => {
					const newTime = time - 1;
					if (newTime === 0) {
						if (timerRef.current) {
							clearInterval(timerRef.current);
							timerRef.current = undefined;
						}
						setDialogState("fail");
						cancelSocket();
					}
					return newTime;
				});
			}, 1000 * 60);
		}
	}, [email, sendMagicLink, loginCallback, cancelSocket]);

	const changeEmail = (ev) => {
		const { value } = ev.currentTarget;
		console.log("CHANGE", value);
		validateEmail(value);
		setEmail(value);
	};

	const cancelLogin = () => {
		if (timerRef.current) {
			clearInterval(timerRef.current);
			timerRef.current = undefined;
		}
		setDialogState("login");
		cancelSocket();
	};

	if (isLoggedIn) {
		// Can we go direct to the user area after logging in pls?
		navigate("/");
	}

	const dialog = {
		login: (
			<LoginPrompt
				isEmailValid={isEmailValid}
				changeEmail={changeEmail}
				handleLogin={handleLogin}
			/>
		),
		wait: (
			<Wait
				email={email}
				timeLeft={timeLeft}
				cancelLogin={cancelLogin}
				handleLogin={handleLogin}
			/>
		),
		success: <Success />,
		fail: <Fail cancelLogin={cancelLogin} error={error} />
	};

	return (
		<Layout>
			<Page
				minHeight="90vh"
				middle={dialog[dialogState]}
			/>
		</Layout>
	);
};
export default Login;
