import React from "react";
import LoginImage from "./LoginImage";

const Success = ({ text }) => (
	<>
		<h1>
			Verified it&apos;s you,
			<br />
			{ text }
		</h1>
		<div>
			<div>
				<LoginImage image="success-icon.svg" alt="success" />
			</div>
		</div>
	</>
);

Success.defaultProps = {
	text: "logging you in now..."
};

export default Success;
