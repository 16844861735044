import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";

const StyledImage = styled.img`
	height: 4.5em;
	color: white;
`;

const LoginImage = ({ image, alt }) => {
	const { allFile } = useStaticQuery(
		graphql`
			query {
				allFile(filter: {sourceInstanceName: {eq: "images"}, extension: {eq: "svg"}}) {
					edges {
						node {
							publicURL,
							name,
							ext
						}
					}
				}
			}
		`
	);

	const imageInfo = allFile.edges.find((f) => `${f.node.name}${f.node.ext}` === image);
	const { publicURL } = imageInfo?.node ?? {};

	return (
		<StyledImage src={publicURL} alt={alt} />
	);
};

export default LoginImage;
