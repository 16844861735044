import React from "react";
import styled from "styled-components";
import Flex from "../layout/Flex";

const StyledSignInDiv = styled.div`
	.signIn {
		width: 100%;
		height: 42px;
		margin-top: 5px;
	}
`;

const LoginPrompt = ({
	isEmailValid, changeEmail, handleLogin
}) => (
	<>
		<h1>Client Area</h1>
		<p>Keeping you in touch with your project.</p>
		<Flex
			flexDirection="column"
			maxWidth="450px"
		>
			<p>
				<label htmlFor="email">
					Email
					<input
						className="fluid"
						type="email"
						name="email"
						placeholder="Email"
						onChange={changeEmail}
					/>
				</label>
			</p>
			<input
				className="fluid"
				type="button"
				value="Log In"
				disabled={!isEmailValid}
				onClick={handleLogin}
			/>
			<br />
			<StyledSignInDiv>
				or
				<div id="appleid-signin" className="signIn" data-color="white" data-border="true" data-type="continue" />
			</StyledSignInDiv>
		</Flex>
	</>
);

export default LoginPrompt;
